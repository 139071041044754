import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PageTitle from '../PageTitle';

function ShortStoriesMain() {
  const [stories, setStories] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/story_list.json");
        const jsonData = await response.json();
        console.log(jsonData);
        setStories(jsonData);
      } catch(error) {
        console.log(error, "error");
      }
    };
    fetchData();
  }, []);

  return (
    <div className="container-sm thin-container">
      <PageTitle title="Short Stories" />

      <Link to="/">
        <img src="tibbs-logo-border.svg" className="my-2" width="324px" height="154px" alt="Tibbs Logo" />
      </Link>

      <div className="filled-area p-4">
        <h1 className="mb-4">&gt;Short Stories</h1>

        <p>These stories are all free to read. Look out for my debut short story collection!</p>
        
      </div>

      {stories.map(story_info => (
        <Link to={"/short-stories/"+story_info.slug}>
          <div className="filled-area-item p-4 m-3 d-flex flex-column">
            <div className="d-flex flex-row justify-content-between">

              <div className="align-self-center">
                <h3>{story_info.name}</h3>
              </div>

              <div className="align-self-center text-end">
                <h4 className="text-right">{story_info.read_time} min read</h4>
              </div>
            </div>

            <div className="mt-3">
              <div>
                <p>{story_info.desc}</p>
              </div>
            </div>

          </div>
        </Link>
      ))}
      
    </div>
  );
}

export default ShortStoriesMain;