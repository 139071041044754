import './App.css';
import { Route, Routes } from 'react-router-dom';

import MainMenu from './pages/MainMenu';
import AboutPage from './pages/AboutPage';
import ShortStoriesMain from './pages/ShortStoriesMain';
import StoryRead from './pages/StoryRead';
import ListsMain from './pages/ListsMain';
import ListView from './pages/ListView';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<MainMenu/>} />
        <Route path='/about' element={<AboutPage/>} />
        <Route path='/short-stories' element={<ShortStoriesMain/>} />
        <Route path='/short-stories/:story_slug' element={<StoryRead/>} />
        <Route path='/lists' element={<ListsMain/>} />
        <Route path='/lists/:list_slug' element={<ListView/>} />
      </Routes>
      
    </div>
  );
}

export default App;
