import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import PageTitle from '../PageTitle';

function ListView() {
  let { list_slug } = useParams();

  const [lists, setLists] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/list_list.json");
        const jsonData = await response.json();
        console.log(jsonData);
        setLists(jsonData);
      } catch(error) {
        console.log(error, "error");
      }
    };
    fetchData();
  }, []);

  let list_info = lists.find((li) => li["slug"] === list_slug) || {
    "slug": list_slug,
    "name": "",
    "items": []
  }

  return (
    <div className="container-sm thin-container pb-3">
      <PageTitle title={list_info.name} />

      <Link to="/">
        <img src="/tibbs-logo-border.svg" className="w-25 my-2" alt="Tibbs Logo" />
      </Link>

      <div className="filled-area p-4 mb-1 d-flex flex-column">
        <div className="pb-3 mb-3 border-bottom border-dark">
          <h4><Link to="/lists">&larr; Back to Lists</Link></h4>
        </div>

        <h2>{list_info.name}</h2>
      </div>

      {list_info.items.map(list_item => (
        <div className="d-flex justify-content-center">
          <div className="filled-area-item list-item px-4 py-2 mx-3 my-1">
            <div className="d-flex flex-row justify-content-between">

              <div className="align-self-center">
                <p><b>{list_item.rank + ". " + list_item.name}</b></p>
              </div>

              {list_item.second &&
              <div className="align-self-center text-end">
                <p>{list_item.second}</p>
              </div>
              }

            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ListView;