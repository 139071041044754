import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import PageTitle from '../PageTitle';

function StoryRead() {
  let { story_slug } = useParams();

  const [stories, setStories] = useState([]);

  const [storyText, setStoryText] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/story_list.json");
        const jsonData = await response.json();
        console.log(jsonData);
        setStories(jsonData);
      } catch(error) {
        console.log(error, "error");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/story_text/"+story_slug+".txt");
        const txtData = await response.text();
        setStoryText(txtData);
      } catch(error) {
        console.log(error, "error");
      }
    };
    fetchData();
  }, [story_slug]);

  let story_info = stories.find((si) => si["slug"] === story_slug) || {
    "name": story_slug,
    "slug": "",
    "desc": "",
    "read_time": "",
  };

  var paragraphs = storyText.split(/(?:\r?\n)/);

  return (
    <div className="container-sm thin-container">
      <PageTitle title={story_info["name"]} />

      <Link to="/">
        <img src="/tibbs-logo-border.svg" className="w-25 my-2" alt="Tibbs Logo" />
      </Link>

      <div className="filled-area p-4 d-flex flex-column">
        <div className="pb-3 mb-3 border-bottom border-dark">
          <h4><Link to="/short-stories">&larr; Back to List of Stories</Link></h4>
        </div>

        <div className="d-flex flex-row justify-content-between">

          <div className="align-self-center">
            <h3>{story_info.name}</h3>
          </div>

          <div className="align-self-center text-end">
            <h4 className="text-right">{story_info.read_time} min read</h4>
          </div>
        </div>

        <div className="mt-3">
          <div>
            <p>{story_info.desc}</p>
          </div>
        </div>

      </div>

      <div className="d-flex justify-content-center">
        <div className="filled-area-item story-read p-4 m-3">

          {paragraphs.map(para => (
            <p className="mb-2" dangerouslySetInnerHTML={{ __html: (para === "" ? "\u00A0" : para) }}></p>
          ))}
          
        </div>
      </div>
    </div>
  );
}

export default StoryRead;